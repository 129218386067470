import { default as indexutcnovDtlEMeta } from "/vercel/path0/pages/about/index.vue?macro=true";
import { default as _91slug_933y16X4DEIbMeta } from "/vercel/path0/pages/articles/[slug].vue?macro=true";
import { default as indexHSIYyOmJPsMeta } from "/vercel/path0/pages/articles/index.vue?macro=true";
import { default as _91page_93VXMatCb201Meta } from "/vercel/path0/pages/articles/page/[page].vue?macro=true";
import { default as favouritesawWR6M7uhAMeta } from "/vercel/path0/pages/browse/favourites.vue?macro=true";
import { default as indexXj72jxrSl8Meta } from "/vercel/path0/pages/browse/index.vue?macro=true";
import { default as recently_45viewedmVezMt7tp7Meta } from "/vercel/path0/pages/browse/recently-viewed.vue?macro=true";
import { default as tableuiIFQvQAFrMeta } from "/vercel/path0/pages/browse/table.vue?macro=true";
import { default as _91slug_93R8CanxH8ldMeta } from "/vercel/path0/pages/careers/[slug].vue?macro=true";
import { default as indexoPfiXD35qmMeta } from "/vercel/path0/pages/careers/index.vue?macro=true";
import { default as indexDBbnZWdxZ3Meta } from "/vercel/path0/pages/contact/index.vue?macro=true";
import { default as appraisalrZwE4SA8G6Meta } from "/vercel/path0/pages/crm/appraisal.vue?macro=true";
import { default as digitalQP91VK0O8NMeta } from "/vercel/path0/pages/crm/digital.vue?macro=true";
import { default as appraisalUZab0HRpG5Meta } from "/vercel/path0/pages/crm/lettings/appraisal.vue?macro=true";
import { default as registerOCMAlToGe3Meta } from "/vercel/path0/pages/crm/lettings/register.vue?macro=true";
import { default as viewingYhL2DT7czOMeta } from "/vercel/path0/pages/crm/lettings/viewing.vue?macro=true";
import { default as registerAg8b72fF3XMeta } from "/vercel/path0/pages/crm/register.vue?macro=true";
import { default as vebrahOFz1JQn9NMeta } from "/vercel/path0/pages/crm/vebra.vue?macro=true";
import { default as viewinga0mEVjsEXYMeta } from "/vercel/path0/pages/crm/viewing.vue?macro=true";
import { default as indexA1lXNiIKrGMeta } from "/vercel/path0/pages/digital-valuation/index.vue?macro=true";
import { default as _91slug_93jzLkOxVcgaMeta } from "/vercel/path0/pages/estate-agent/[slug].vue?macro=true";
import { default as _91slug_93WgPQCKTeTcMeta } from "/vercel/path0/pages/fees/[slug].vue?macro=true";
import { default as indexeqQOKrUGnRMeta } from "/vercel/path0/pages/fees/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexoOBDY93e9EMeta } from "/vercel/path0/pages/login/index.vue?macro=true";
import { default as indexObvUB5x2jcMeta } from "/vercel/path0/pages/mailing-list/index.vue?macro=true";
import { default as _91slug_933IUKTQbiyHMeta } from "/vercel/path0/pages/people/[slug].vue?macro=true";
import { default as _91slug_93un7ONJp8l3Meta } from "/vercel/path0/pages/policies/[slug].vue?macro=true";
import { default as indexy9KbOHdOwVMeta } from "/vercel/path0/pages/policies/index.vue?macro=true";
import { default as _91slug_93W0fMVY6rdVMeta } from "/vercel/path0/pages/property-services/[slug].vue?macro=true";
import { default as _91slug_93sPP2vWeT8SMeta } from "/vercel/path0/pages/property/[slug].vue?macro=true";
import { default as _91slug_93QzAaZDBqRGMeta } from "/vercel/path0/pages/rural/[slug].vue?macro=true";
import { default as index37LAzIgvgVMeta } from "/vercel/path0/pages/rural/index.vue?macro=true";
import { default as indexDqV3Lxa5AvMeta } from "/vercel/path0/pages/search/index.vue?macro=true";
import { default as _91slug_93Kexw2B8hhCMeta } from "/vercel/path0/pages/success-stories/[slug].vue?macro=true";
import { default as indexFbPr16hdphMeta } from "/vercel/path0/pages/success-stories/index.vue?macro=true";
import { default as _91slug_93mq2UWauqiyMeta } from "/vercel/path0/pages/thank-you/[slug].vue?macro=true";
import { default as why_45butler_45sherbornZNgZNTD4txMeta } from "/vercel/path0/pages/why-butler-sherborn.vue?macro=true";
import { default as component_45stub5X4Ei38PMgMeta } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub5X4Ei38PMg } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/vercel/path0/pages/about/index.vue")
  },
  {
    name: "articles-slug",
    path: "/articles/:slug()",
    meta: _91slug_933y16X4DEIbMeta || {},
    component: () => import("/vercel/path0/pages/articles/[slug].vue")
  },
  {
    name: "articles",
    path: "/articles",
    meta: indexHSIYyOmJPsMeta || {},
    component: () => import("/vercel/path0/pages/articles/index.vue")
  },
  {
    name: "articles-page-page",
    path: "/articles/page/:page()",
    meta: _91page_93VXMatCb201Meta || {},
    component: () => import("/vercel/path0/pages/articles/page/[page].vue")
  },
  {
    name: "browse-favourites",
    path: "/browse/favourites",
    component: () => import("/vercel/path0/pages/browse/favourites.vue")
  },
  {
    name: "browse",
    path: "/browse",
    component: () => import("/vercel/path0/pages/browse/index.vue")
  },
  {
    name: "browse-recently-viewed",
    path: "/browse/recently-viewed",
    component: () => import("/vercel/path0/pages/browse/recently-viewed.vue")
  },
  {
    name: "browse-table",
    path: "/browse/table",
    component: () => import("/vercel/path0/pages/browse/table.vue")
  },
  {
    name: "careers-slug",
    path: "/careers/:slug()",
    component: () => import("/vercel/path0/pages/careers/[slug].vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/vercel/path0/pages/careers/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/vercel/path0/pages/contact/index.vue")
  },
  {
    name: "crm-appraisal",
    path: "/crm/appraisal",
    meta: appraisalrZwE4SA8G6Meta || {},
    component: () => import("/vercel/path0/pages/crm/appraisal.vue")
  },
  {
    name: "crm-digital",
    path: "/crm/digital",
    meta: digitalQP91VK0O8NMeta || {},
    component: () => import("/vercel/path0/pages/crm/digital.vue")
  },
  {
    name: "crm-lettings-appraisal",
    path: "/crm/lettings/appraisal",
    meta: appraisalUZab0HRpG5Meta || {},
    component: () => import("/vercel/path0/pages/crm/lettings/appraisal.vue")
  },
  {
    name: "crm-lettings-register",
    path: "/crm/lettings/register",
    meta: registerOCMAlToGe3Meta || {},
    component: () => import("/vercel/path0/pages/crm/lettings/register.vue")
  },
  {
    name: "crm-lettings-viewing",
    path: "/crm/lettings/viewing",
    meta: viewingYhL2DT7czOMeta || {},
    component: () => import("/vercel/path0/pages/crm/lettings/viewing.vue")
  },
  {
    name: "crm-register",
    path: "/crm/register",
    meta: registerAg8b72fF3XMeta || {},
    component: () => import("/vercel/path0/pages/crm/register.vue")
  },
  {
    name: "crm-vebra",
    path: "/crm/vebra",
    component: () => import("/vercel/path0/pages/crm/vebra.vue")
  },
  {
    name: "crm-viewing",
    path: "/crm/viewing",
    meta: viewinga0mEVjsEXYMeta || {},
    component: () => import("/vercel/path0/pages/crm/viewing.vue")
  },
  {
    name: "digital-valuation",
    path: "/digital-valuation",
    component: () => import("/vercel/path0/pages/digital-valuation/index.vue")
  },
  {
    name: "estate-agent-slug",
    path: "/estate-agent/:slug()",
    component: () => import("/vercel/path0/pages/estate-agent/[slug].vue")
  },
  {
    name: "fees-slug",
    path: "/fees/:slug()",
    component: () => import("/vercel/path0/pages/fees/[slug].vue")
  },
  {
    name: "fees",
    path: "/fees",
    component: () => import("/vercel/path0/pages/fees/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: indexoOBDY93e9EMeta || {},
    component: () => import("/vercel/path0/pages/login/index.vue")
  },
  {
    name: "mailing-list",
    path: "/mailing-list",
    component: () => import("/vercel/path0/pages/mailing-list/index.vue")
  },
  {
    name: "people-slug",
    path: "/people/:slug()",
    meta: _91slug_933IUKTQbiyHMeta || {},
    component: () => import("/vercel/path0/pages/people/[slug].vue")
  },
  {
    name: "policies-slug",
    path: "/policies/:slug()",
    component: () => import("/vercel/path0/pages/policies/[slug].vue")
  },
  {
    name: "policies",
    path: "/policies",
    component: () => import("/vercel/path0/pages/policies/index.vue")
  },
  {
    name: "property-services-slug",
    path: "/property-services/:slug()",
    component: () => import("/vercel/path0/pages/property-services/[slug].vue")
  },
  {
    name: "property-slug",
    path: "/property/:slug()",
    component: () => import("/vercel/path0/pages/property/[slug].vue")
  },
  {
    name: "rural-slug",
    path: "/rural/:slug()",
    meta: _91slug_93QzAaZDBqRGMeta || {},
    component: () => import("/vercel/path0/pages/rural/[slug].vue")
  },
  {
    name: "rural",
    path: "/rural",
    meta: index37LAzIgvgVMeta || {},
    component: () => import("/vercel/path0/pages/rural/index.vue")
  },
  {
    name: "search",
    path: "/search",
    meta: indexDqV3Lxa5AvMeta || {},
    component: () => import("/vercel/path0/pages/search/index.vue")
  },
  {
    name: "success-stories-slug",
    path: "/success-stories/:slug()",
    component: () => import("/vercel/path0/pages/success-stories/[slug].vue")
  },
  {
    name: "success-stories",
    path: "/success-stories",
    component: () => import("/vercel/path0/pages/success-stories/index.vue")
  },
  {
    name: "thank-you-slug",
    path: "/thank-you/:slug()",
    component: () => import("/vercel/path0/pages/thank-you/[slug].vue")
  },
  {
    name: "why-butler-sherborn",
    path: "/why-butler-sherborn",
    component: () => import("/vercel/path0/pages/why-butler-sherborn.vue")
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/articles/page",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/people",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/properties",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/estate-agent",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/property-blog/:pathMatch(.*)",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/browse/latest-properties-sale",
    component: component_45stub5X4Ei38PMg
  }
]