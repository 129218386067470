
import * as bunnyRuntime$YWIDwL16qC from '/vercel/path0/node_modules/@nuxt/image/dist/runtime/providers/bunny'
import * as sanityRuntime$C5hUg0WOof from '/vercel/path0/node_modules/@nuxt/image/dist/runtime/providers/sanity'
import * as vercelRuntime$b8uWPRx1qM from '/vercel/path0/node_modules/@nuxt/image/dist/runtime/providers/vercel'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "default": {
      "quality": 80,
      "modifiers": {
        "format": "auto"
      }
    }
  },
  "provider": "vercel",
  "domains": [
    "cdn.sanity.io",
    "www.butlersherborn.co.uk",
    "lh3.googleusercontent.com",
    "img.youtube.com",
    "i.vimeocdn.com"
  ],
  "alias": {
    "/youtube": "https://img.youtube.com",
    "/vimeo": "https://i.vimeocdn.com"
  },
  "densities": [
    1,
    2
  ],
  "format": [
    "avif",
    "webp",
    "png",
    "webp"
  ],
  "quality": 80
}

imageOptions.providers = {
  ['bunny']: { provider: bunnyRuntime$YWIDwL16qC, defaults: {"baseURL":"https://butlersherborn-alto.b-cdn.net"} },
  ['sanity']: { provider: sanityRuntime$C5hUg0WOof, defaults: {"projectId":"js7r23bq"} },
  ['vercel']: { provider: vercelRuntime$b8uWPRx1qM, defaults: {} }
}
        